import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InternalCommunicationService } from 'src/app/services/Communication/internal-communication.service';
import { TenderService } from 'src/app/services/Tender/tender.service';
import { Tender } from 'src/app/shared/objects/tender';
import { Establishment } from 'src/app/shared/objects/establishment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    CommonModule
  ],
  selector: 'app-tender-meta-data',
  templateUrl: './tender-meta-data.component.html',
  styleUrls: ['./tender-meta-data.component.scss']
})
export class TenderMetaDataComponent implements OnInit, OnDestroy {

  @Input() tender?: Tender;
  @Input() establishement?: Establishment;
  @Input() tenderTotal?: BehaviorSubject<number>;
  @Input() isReadOnly?: boolean;

  selectedIconSource?: string
  tenderStatusItems: any[]
  automaticFillTenderName: boolean;
  automaticFillExpirationDate: boolean;
  initialDepositAccordingToDefault?: string;
  showCalculation: boolean = false;
  tTotal?: number;
  initialDepositDefault?: number;
  buttonColor?: string;
  subscribtions: (Subscription | undefined)[] = []; 

  constructor(private changeDector: ChangeDetectorRef,private tenderService: TenderService, private communication: InternalCommunicationService) {
    this.tenderStatusItems = this.tenderService.getTenderStatusList();

    this.automaticFillTenderName = false;
    this.automaticFillExpirationDate = false;
  }

  ngOnInit(): void {
    this.selectionChanged();
    if (this.establishement) {
      this.automaticFillTenderName = this.establishement.determineTenderNameAutomaticaly;
      this.toggleAutomaticExpirationDate(this.establishement.determineExpirationDateAutomatically ?? false, true);

      // Only subscribe if needed
      this.subscribtions.push(this.communication.proposedTenderName.subscribe(() => {
        if (this.automaticFillTenderName && this.tender?.guestValues) {

          if(!this.tender.guestValues.firstname && !this.tender.guestValues.lastname){
            this.tender.tenderName = "";
          } else {
            this.tender.tenderName = `${this.tender.guestValues.lastname ?? ""}, ${this.tender.guestValues.firstname ?? ""} ${this.tender.guestValues.insertion ?? ""}`;
          }
        }
      }));
      
    }

    this.subscribtions.push(this.tenderTotal?.subscribe(tenderTotal => {
      // Only accept update if value is present and not 0
      if(!tenderTotal || tenderTotal == 0){
        return;
      }

      this.tTotal = tenderTotal;
      this.initialDepositDefault = (tenderTotal / 100) * (this.establishement?.defaultInitialDepositPercentage ?? 0);
      this.initialDepositAccordingToDefault = `€ ${this.initialDepositDefault.toFixed(2)}`;
      this.changeDector.detectChanges();
    }));
  }

  ngOnDestroy(): void {
    this.subscribtions.forEach(s => s?.unsubscribe());
  }

  /**
   * This method will show the default deposit calculation
   */
  showDefaultInitialDepositCalculation() {
    this.showCalculation = !this.showCalculation;

    if (this.establishement?.defaultInitialDepositPercentage) {
      this.initialDepositAccordingToDefault = this.showCalculation ?
        `${this.tTotal} * ${(this.establishement.defaultInitialDepositPercentage / 100)} = ${this.initialDepositDefault?.toFixed(2)}` :
        `€ ${this.initialDepositDefault?.toFixed(2)}`
    }
  }

  /**
   * This method will toggle the automatic tender name function
   */
  toggleAutomaticTenderName(setState: boolean) {
    if(this.isReadOnly){
      return;
    }
    
    this.automaticFillTenderName = setState;
  }

  /**
   * This method will toggle the automatic expirationDate function
   */
  toggleAutomaticExpirationDate(setState: boolean, initialSetter: boolean = false) {
    if(this.isReadOnly){
      return;
    }

    this.automaticFillExpirationDate = setState;
    if(initialSetter && this.tender?.$id){
      return;
    }

    if(setState && this.tender && this.establishement){
      const now = new Date();
      now.setDate(now.getDate() + (this.establishement.defaultDaysBeforeExpirering ?? 0));

      this.tender.expirationDate = now;
    } else if(this.tender){
      this.tender.expirationDate = undefined;
    }
  }


  /**
   * This method will set the icon source for the tender status
   * @returns T
   */
  selectionChanged() {
    if (!this.tender || !this.tenderStatusItems) {
      return;
    }

    var selectedStatus = this.tenderStatusItems.filter(i => i.value == this.tender?.tenderStatus);

    if (selectedStatus && selectedStatus?.length > 0) {
      this.selectedIconSource = selectedStatus[0].iconSource;
      this.buttonColor = selectedStatus[0].color;
    }
  }

}
