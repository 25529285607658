<div class="container task-management">
    @if(shadowTaskList.length != 0){
        <div class="row active-tasks">
            <app-active-tasks class="active-tasks-component" [user]="user" [hideActions]="true" [hideTenderName]="true" [tasks]="tasks" [tender]="tender"></app-active-tasks>
        </div>
    }
    <div class="row">
        @if(warningFeedback){
            <div class="success-warning">
                <div class="alert alert-warning" role="alert">
                    <strong>Let op!</strong> {{warningFeedback}}
                </div>
            </div>
        }
    </div>
    @if(!isViewer){
        <div class="row new-tasks-component">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <h5 class="col card-title">Taak toevoegen</h5>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label for="task-name">Taak naam</label>
                            <input type="input" class="form-control" id="task-name" placeholder="Taak naam" [(ngModel)]="newTask.taskName">
                            @if(((newTask.taskName?.length ?? 0) < 2 || !newTask.status) && showFeedback){
                                <div class="invalid-feedback show">
                                    Een taak naam en een status voor de taak zijn verplicht alvorens deze kan worden opgeslagen.
                                </div>
                            }
                        </div>
                        <div class="form-group col-2">
                            <label for="task-expiration-date">Vervaldatum</label>
                            <input type="date" max="9999-12-31" class="form-control" id="task-expiration-date" placeholder="Vervaldatum" [(ngModel)]="newTask.deadlineDate">
                        </div>
                        <div class="form-group col-3">
                            <label for="task-status">Status</label>
                            <select class="form-select" [(ngModel)]="newTask.status">
                                @for(opt of taskService.taskStatusItems; track opt.description){
                                    <option [ngValue]="opt.value"><span>{{opt.description}}</span></option>
                                }
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label for="task-description">Teamgenoten koppelen (Zoek op naam of e-mailadres)</label>
                            <input class="form-control" placeholder="Naam of e-mailadres..." type="text" [(ngModel)]="searchForUser" (ngModelChange)="attachedUserInputChanged()"/>
                            @if(optionalMembers?.length ?? 0 > 0){
                                <div class="optional-to-attach">
                                    <label for="task-description">Teamgenoten om te koppelen:</label>
                                    <div class="optional-member">
                                        @for(optionalMember of optionalMembers; track optionalMember.$id){
                                            <div class="member-to-add">
                                                <span>{{optionalMember.userName}}</span><div class="add-member" (click)="attachUser(optionalMember)"><i class="bi bi-plus-circle"></i></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            @if(attachedMembers?.length ?? 0 > 0){
                                <div class="optional-to-attach">
                                    <label for="task-description">Gekoppelde teamgenoten:</label>
                                    <div class="optional-member">
                                        @for(attachedMember of attachedMembers; track attachedMember.$id){
                                            <div class="member-to-add">
                                                <span>{{attachedMember.userName}}</span><div class="remove-member" (click)="detachUser(attachedMember)"><i class="bi bi-x-circle"></i></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col">
                            <label for="task-description">Omschrijving</label>
                            <textarea rows="3" class="form-control" id="task-description" placeholder="Omschrijving" [(ngModel)]="newTask.taskDescription"></textarea>
                        </div>
                        <div class="form-group col-sm-auto task-actions">
                            <button class="btn btn-secondary icon-button" id="save-task" (click)="clearNewTask()">
                                <i class="bi bi-x-circle"></i>
                                <span>Legen</span>
                            </button>
                            <button class="btn btn-success icon-button" id="save-task" (click)="saveTask()">
                                <i class="bi bi-save"></i>
                                @if(newTask.$id){
                                    <span>Opslaan</span>
                                } @else {
                                    <span>Toevoegen</span>
                                }
                            </button>
                        </div>
                    </div>
                    <div class="row quick-actions">
                        <span>Snelle acties:</span>
                        <div class="quick-task-actions">
                            <button class="btn btn-secondary icon-button" (click)="quickAction('deposit')">
                                <i class="bi bi-currency-euro"></i>
                                <span>Aanbetalingsverzoek</span>
                            </button>
                            <button class="btn btn-secondary icon-button" (click)="quickAction('print sheet')">
                                <i class="bi bi-printer"></i>
                                <span>Sheet printen</span>
                            </button>
                            <button class="btn btn-secondary icon-button" (click)="quickAction('recieve consonance')">
                                <i class="bi bi-check-circle"></i>
                                <span>Akkoord Ontvangen</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    @if(!tender?.$id){
        <div class="blur">
            <div class="blur-message">
                <span>Sla deze offerte op om taken toe te kunnen voegen</span>
                <button class="btn btn-success" (click)="saveTenderAction()">Offerte opslaan</button>
            </div>
        </div>
    }
</div>