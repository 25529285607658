import { Injectable } from '@angular/core';
import { AppwriteException, ID, Models, Permission, Query, Role } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { BTender } from 'src/app/shared/objects/backend/BTender';
import { Arrangement } from 'src/app/shared/objects/components/arrangement';
import { GuestValues } from 'src/app/shared/objects/components/guest-values';
import { Tender } from 'src/app/shared/objects/tender';
import { TenderStatistics } from 'src/app/shared/objects/tender-statistics';
import { ArrangementService } from '../Arrangement/arrangement.service';
import { TimeLineService } from '../TimeLine/time-line.service';
import { ChecklistService } from '../Checklist/checklist.service';
import { TaskService } from '../Task/task.service';
import { TimeLineItem } from 'src/app/shared/objects/components/time-line-item';
import { Checklist } from 'src/app/shared/objects/components/checklist';
import { environment } from 'src/environments/environment';
import { PermissionService } from '../Permission/permission.service';
import { TenderGuestValuesRelation } from 'src/app/shared/objects/tenderGuestValuesRelation';
import { BehaviorSubject, last } from 'rxjs';
import { InternalCommunicationService } from '../Communication/internal-communication.service';
import { TenderMetaData } from 'src/app/shared/objects/tender-meta-data';
import { InvoiceValuesService } from '../InvoiceValues/invoice-values.service';

@Injectable({
  providedIn: 'root'
})
export class TenderService {

  private tenderStatusItems: any[];

  constructor(private invoiceValuesService: InvoiceValuesService, private taskService: TaskService, private arrangementService: ArrangementService, private timeLineService: TimeLineService, private checklistService: ChecklistService, private permissionsService: PermissionService, private communicationService: InternalCommunicationService) {
    this.tenderStatusItems = this.getTenderStatusList();
  }

  async addTender(tender: Tender, teamId: string) {
    var writePermissions = this.permissionsService.getWritePermissions();
    return Api.database.createDocument(environment.database, "Tenders", ID.unique(), this.createPartialTender(tender), [Permission.read(Role.team(teamId)), ...writePermissions]);
  }

  /**
   * This method will return a TenderNumber according to the format
   * @returns 
   */
  async getTenderNumber() : Promise<string> {
    var randomId = Math.floor(Math.random() * 1000000);
    var formattedId = randomId.toString();
    while(formattedId.length < 7){
      formattedId = `0${formattedId}`;
    }

    var tenderNumber = `${new Date().getFullYear().toString().substring(2, 4)}0${formattedId}`;

    // Check if tenderNumber is unique for this establishment
    var searchResult = await Api.database.listDocuments<BTender>(environment.database, "Tenders", [Query.equal('uniqueTenderNumber', tenderNumber), Query.limit(1)]);

    if(searchResult?.documents?.length > 0){
      return this.getTenderNumber();
    }

    return Promise.resolve(tenderNumber);
  }

  /**
   * Store new tender
   * @param tender 
   * @param teamId 
   * @returns 
   */
  async createTender(tender: Tender, teamId: string, overrideDate: Date | undefined = undefined, overrideMetaData: TenderMetaData | undefined = undefined, failureFeedback: any = undefined): Promise<string | undefined> {
    var writePermissions = this.permissionsService.getWritePermissions();
    return Api.database.createDocument(environment.database, "Tenders", ID.unique(), this.createPartialTender(tender, overrideDate, overrideMetaData), [Permission.read(Role.team(teamId)), ...writePermissions]).then(async createdTender => {
      if (!createdTender || !tender.guestValues) {
        return;
      }

      // Invoice Values
      if(tender.invoiceValues && (!this.invoiceValuesService.isEmpty(tender.invoiceValues) || tender.invoiceValues?.ascriptionFromTender || tender.invoiceValues?.emailAdressFromTender)){
        // Set reference Id
        tender.invoiceValues.tenderId = createdTender.$id;
        
        // Empty ascription and emailadres if linked with Tender
        if(tender.invoiceValues.ascriptionFromTender){
          tender.invoiceValues.ascription = undefined;
        }

        if(tender.invoiceValues.emailAdressFromTender){
          tender.invoiceValues.emailAdress = undefined;
        }

        // Create InvoiceValues
        await this.invoiceValuesService.createInvoiceValues(tender.invoiceValues, teamId, writePermissions).then(createdInvoiceValues => {
          tender.invoiceValues!.$id = createdInvoiceValues.$id;
        }).catch(() => {
          failureFeedback?.push({
            tenderId: tender.$id,
            reason: `Unable to create InvoiceValues`,
            tenderName: tender.tenderName
          });
        });
      }

      if(tender.guestValues.$id){
        await this.checkIfGuestValuesRelationExists(createdTender.$id).then(async exists => {
          if(!exists && tender.guestValues){
            // Create relation row for Tender and GuestValues
            await this.createTenderGuestValuesRelation(tender.guestValues!.$id, createdTender.$id, writePermissions, teamId);
            tender.guestValues!.tenderId = undefined;
          }
        });

        // Update GuestValues
        await this.updateGuestValues(tender.guestValues);
      } else {
        var createdGuestValues = await this.createGuestValues(tender.guestValues, teamId, writePermissions)
        
        // Create relation row for Tender and GuestValues
        await this.createTenderGuestValuesRelation(createdGuestValues.$id, createdTender.$id, writePermissions, teamId);
      }

      if (tender.arrangements && tender.arrangements?.length > 0) {
        // Use the [...] notation to prevent from adjusting the original object when creating a Tender as copy
        var arrangementsToAdd = [...tender.arrangements];
        await Promise.all(arrangementsToAdd.map(async (arrangement) => {
          arrangement.idInTender = createdTender.$id;
          return this.createArrangement(arrangement, teamId, writePermissions);
        }));
      }

      if (tender.timeLine?.timeLineItems) {
        var timeLineItemToAdd = [...tender.timeLine?.timeLineItems];
        await Promise.all(timeLineItemToAdd.map(async (timeLineItem) => {
          // Only if there is at least one of the value present
          if(timeLineItem.startTimeLine || timeLineItem.endTimeLine || timeLineItem.location || timeLineItem.description){
            timeLineItem.tenderId = createdTender.$id;
            return this.timeLineService.createTimeLine(timeLineItem, teamId, writePermissions);
          } 

          return Promise.resolve();
        }));
      };

      // Remove old checklist items
      if(tender.partyValues?.checklistItemsToRemove){
        await Promise.all(tender.partyValues?.checklistItemsToRemove.map(async (checkList) => {
          if(checkList.$id){
            await this.checklistService.deleteTenderChecklist(checkList).then(() => {
              checkList.checklistItems?.map(checklistItemToRemove => {
                if(checklistItemToRemove.$id){
                  return this.checklistService.deleteTenderChecklistItem(checklistItemToRemove);
                }

                return Promise.resolve();
              });
            });
          }
        }));

        tender.partyValues.checklistItemsToRemove = [];
      }

        if (tender.partyValues?.checklists) {
          var checklistsToAdd = [...tender.partyValues?.checklists];
          await Promise.all(checklistsToAdd.map(async (checklist) => {
            checklist.tenderId = createdTender.$id;

            return await this.checklistService.addTenderChecklist(checklist, teamId, writePermissions).then(async createdChecklist => {
              if (checklist.checklistItems) {
                var checklistItemsToAdd = [...checklist.checklistItems];
                await Promise.all(checklistItemsToAdd.map(checklistItem => {
                  checklistItem.checklistId = createdChecklist.$id;
                  return this.checklistService.addTenderChecklistItem(checklistItem, teamId, writePermissions);
                }));
              }
            });
          }));
        };

        return createdTender.$id;
    });
  }

  /**
   * This method will update or add a tender to the list of current tenders in de communication service
   */
  addOrUpdateToCurrentTenderList(tender: Tender){
    if(!tender || !this.communicationService){
      return;
    }

    // Get current value
    var activeTenders = this.communicationService.activeTenders?.getValue();

    if(!activeTenders){
      return;
    }

    // If exisiting check if tender is present, if yes --> Replace, else Add
    var existingTender = activeTenders.filter(t => t.$id == tender.$id);

    if(existingTender.length > 0){
      var indexOf = activeTenders.indexOf(existingTender[0]);
      activeTenders[indexOf] = tender;
    } else {
      var replaceList = [...activeTenders];
      replaceList.push(tender);

      this.communicationService.activeTenders?.next(replaceList);
    }
  }

  /**
   * This method will
   * @param searchInput 
   */
  searchExistingGuest(searchInput: string){
    return Api.database.listDocuments<GuestValues>(environment.database, "GuestValues", [Query.search("emailAdress", searchInput), Query.limit(100)]);
  }

  /**
   * This method will create a relation between a Tender and a set of GuestValues
   * @param createdGuestValues 
   * @param createdTender 
   */
  createTenderGuestValuesRelation(guestValuesId: string, tenderId: string, writePermissions: string[], teamId: string){
    if(!guestValuesId || !tenderId){
      return;
    }

    return Api.database.createDocument(environment.database, "TenderGuestValues", ID.unique(), this.createPartialTenderGuestValuesRelation(guestValuesId, tenderId), [Permission.read(Role.team(teamId)), ...writePermissions]);
  }


  /**
   * Create Partial tender - guestvalues relelation
   * @param guestValuesId 
   * @param tenderId 
   * @returns 
   */
  createPartialTenderGuestValuesRelation(guestValuesId: string, tenderId: string) : Partial<TenderGuestValuesRelation>{
    return {
      tenderId: tenderId,
      guestValuesId: guestValuesId,
    }
  }

  /**
   * Store new guestvalues
   * @param guestValues 
   * @param teamId 
   * @returns 
   */
  createGuestValues(guestValues: GuestValues, teamId: string, writePermissions: string[]) {
    // Save guest values
    return Api.database.createDocument(environment.database, "GuestValues", ID.unique(), this.createPartialGuestValues(guestValues), [Permission.read(Role.team(teamId)), ...writePermissions]);
  }

  /**
   * Delete guestvalues
   */
  deleteGuestValues(id: string) {
    // Save guest values
    return Api.database.deleteDocument(environment.database, "GuestValues", id);
  }

  /**
   * Store a new arrangement
   * @param arrangement 
   */
  createArrangement(arrangement: Arrangement, teamId: string, writePermissions: string[]) {
    return Api.database.createDocument(environment.database, "TenderArrangements", ID.unique(), this.createPartialArrangement(arrangement), [Permission.read(Role.team(teamId)), ...writePermissions]);
  }

  /**
   * This method will update the given tender and the child elements
   * @param tender 
   * @returns 
   */
  updateTender(tender: Tender, teamId: string, failureFeedback: any[] = []): Promise<boolean> {
    var writePermissions = this.permissionsService.getWritePermissions();
    return Api.database.updateDocument(environment.database, "Tenders", tender.$id, this.createPartialTender(tender)).then(async updatedTender => {
      if (!updatedTender || !tender.guestValues) {
        failureFeedback?.push({
          tenderId: tender.$id,
          reason: `Missing Tender to update: ${!updatedTender}. Missing Guestvalues to update: ${!tender?.guestValues}`,
          tenderName: tender.tenderName
        });
        return false;
      }

      // Invoice Values
      if(tender.invoiceValues && (!this.invoiceValuesService.isEmpty(tender.invoiceValues) || tender.invoiceValues?.ascriptionFromTender || tender.invoiceValues?.emailAdressFromTender)){
        // Set reference Id
        tender.invoiceValues.tenderId = tender.$id;
      
        // Empty ascription and emailadres if linked with Tender
        if(tender.invoiceValues.ascriptionFromTender){
          tender.invoiceValues.ascription = undefined;
        }

        if(tender.invoiceValues.emailAdressFromTender){
          tender.invoiceValues.emailAdress = undefined;
        }

        if(tender.invoiceValues.$id){
          // Update existing InvoiceValues
          await this.invoiceValuesService.updateInvoiceValues(tender.invoiceValues).catch(() => {
            failureFeedback?.push({
              tenderId: tender.$id,
              reason: `Unable to update InvoiceValues`,
              tenderName: tender.tenderName
            });
          });
        } else {
          // Create InvoiceValues
          await this.invoiceValuesService.createInvoiceValues(tender.invoiceValues, teamId, writePermissions).then(createdInvoiceValues => {
            if(tender.invoiceValues){
              tender.invoiceValues.$id = createdInvoiceValues.$id;
            }
          }).catch(() => {
            failureFeedback?.push({
              tenderId: tender.$id,
              reason: `Unable to create InvoiceValues`,
              tenderName: tender.tenderName
            });
          });
        }
      }

      // Check if guest values exist in database
      await this.getGuestValues(tender.$id).then(async guestValues => {
        if (!guestValues && tender.guestValues) {
          // Not found so create
          await this.createGuestValues(tender.guestValues, teamId, writePermissions).then(async createdGuestValues => {
            // Create relation row for Tender and GuestValues
            await this.createTenderGuestValuesRelation(createdGuestValues.$id, tender.$id, writePermissions, teamId);
          }).catch(() => {
            failureFeedback?.push({
              tenderId: tender.$id,
              reason: `Unable to create guestvalues`,
              tenderName: tender.tenderName
            });
          });
        } else if (tender.guestValues) {
          await this.checkIfGuestValuesRelationExists(tender.$id).then(async exists => {
            if(!exists && tender.guestValues){
              // Create relation row for Tender and GuestValues
              await this.createTenderGuestValuesRelation(tender.guestValues!.$id, tender.$id, writePermissions, teamId);
              tender.guestValues!.tenderId = undefined;
            }
          });

          // Update GuestValues
          await this.updateGuestValues(tender.guestValues).catch(() => {
            failureFeedback?.push({
              tenderId: tender.$id,
              reason: `Unable to update guestvalues: ${tender.guestValues?.$id}`,
              tenderName: tender.tenderName
            });
          })
        }
      }).catch(() => {
        failureFeedback?.push({
          tenderId: tender.$id,
          reason: `Unable to find guestvalues to update`,
          tenderName: tender.tenderName
        });
      });

      // Remove old checklist items
      if(tender.partyValues?.checklistItemsToRemove){
        await Promise.all(tender.partyValues?.checklistItemsToRemove.map(async (checkList) => {
          if(checkList.$id){
            await this.checklistService.deleteTenderChecklist(checkList).then(() => {
              checkList.checklistItems?.map(checklistItemToRemove => {
                if(checklistItemToRemove.$id){
                  return this.checklistService.deleteTenderChecklistItem(checklistItemToRemove);
                }

                return Promise.resolve();
              });
            });
          }
        }));

        tender.partyValues.checklistItemsToRemove = [];
      }

      var arrangementsOfTender = await this.getArrangementsOfTender(tender.$id);
      var processedArrangementIds: string[] = [];

      // Loop through arrangements and if update or create statement is needed
      if (tender.arrangements && tender.arrangements?.length > 0) {
        await Promise.all(tender.arrangements.map(async (arrangement) => {

          if (arrangementsOfTender?.filter(a => a.$id == arrangement.$id)?.length > 0) {
            // Update arrangement
            await this.updateArrangement(arrangement).catch(() => {
              failureFeedback?.push({
                tenderId: tender.$id,
                reason: `Unable to update arrangement: ${arrangement.$id} - ${arrangement.name}`,
                tenderName: tender.tenderName
              })
            });

            processedArrangementIds.push(arrangement.$id);
          }
          else {
            // Create arrangement
            arrangement.idInTender = tender.$id;
            await this.createArrangement(arrangement, teamId, writePermissions).then(result => {
              processedArrangementIds.push(result.$id);
            }).catch(() => {
              failureFeedback?.push({
                tenderId: tender.$id,
                reason: `Unable to create arrangement: ${arrangement.name}`,
                tenderName: tender.tenderName
              })
            });
          }
        }));
      }

      // Check if there are arrangements which needs to be removed
      if(arrangementsOfTender){
        await Promise.all(arrangementsOfTender?.map(async (existingArrangement) => {
          // If not processed yet check if the arrangement is still existing
          if(!processedArrangementIds?.includes(existingArrangement.$id)){
            await this.deleteArrangement(existingArrangement)
            console.log("Deleting Arrangement", existingArrangement)
          }
        }));
      }

      // Get all current timeLineItems
      var currentTimeLineItems = await this.timeLineService.getTimeLineOfTender(updatedTender.$id);

      if (tender.timeLine?.timeLineItems) {
        await Promise.all(tender.timeLine.timeLineItems.map(async (timeLineItem) => {
          timeLineItem.tenderId = updatedTender.$id;
          
          // Only if there is at least one of the value present
          if(timeLineItem.startTimeLine || timeLineItem.endTimeLine || timeLineItem.location || timeLineItem.description){
            if (timeLineItem.$id == "") {
              return this.timeLineService.createTimeLine(timeLineItem, teamId, writePermissions).catch(() => {
                failureFeedback?.push({
                  tenderId: tender.$id,
                  reason: `Unable to create timeline: ${timeLineItem.description}`,
                  tenderName: tender.tenderName
                })
              });
            } else {
              return this.timeLineService.updateTimeLine(timeLineItem).catch(() => {
                failureFeedback?.push({
                  tenderId: tender.$id,
                  reason: `Unable to update timeline: ${timeLineItem.$id} - ${timeLineItem.description}`,
                  tenderName: tender.tenderName
                })
              });
            }
          } 
        }));

        await Promise.all(currentTimeLineItems.documents.map(async (timeLineItem) => {
          if (tender.timeLine?.timeLineItems.filter(t => t.$id == timeLineItem.$id).length == 0) {
            return this.timeLineService.deleteTimeLine(timeLineItem).catch(() => {
              failureFeedback?.push({
                tenderId: tender.$id,
                reason: `Unable to delete timelineItem: ${timeLineItem.$id}`,
                tenderName: tender.tenderName
              })
            });
          } else {
            return Promise.resolve();
          }
        }));

      } else if (currentTimeLineItems.documents.length > 0) {
        // Remove all TimeLineItems
        await Promise.all(currentTimeLineItems.documents.map(async (timeLineItem) => {
          return this.timeLineService.deleteTimeLine(timeLineItem);
        }));
      }

      if (tender.partyValues?.checklists) {
        await Promise.all(tender.partyValues.checklists.map(checklist => {
          checklist.tenderId = updatedTender.$id;

          if (checklist.$id == "") {
            return this.checklistService.addTenderChecklist(checklist, teamId, writePermissions).then(async createdChecklist => {
              if (createdChecklist && checklist.checklistItems) {
                await Promise.all(checklist.checklistItems.map(checklistItem => {
                  checklistItem.checklistId = createdChecklist.$id;
                  return this.checklistService.addTenderChecklistItem(checklistItem, teamId, writePermissions).catch(() => {
                    failureFeedback?.push({
                      tenderId: tender.$id,
                      reason: `Unable to add CheckListItem: ${checklistItem.$id} - ${checklistItem.name}`,
                      tenderName: tender.tenderName
                    })
                  });
                }));
              }
            })
          } else {
            return this.checklistService.updateTenderChecklistAndItems(checklist, teamId, tender.establishmentId);
          }
        }));
        tender.partyValues.checklists.map(checklist => {
          checklist.tenderId = updatedTender.$id;

        })
      }
      return true;
    });
  }

  /**
   * This method will import a tender into the database
   * @param tender 
   * @returns 
   */
  async importTender(tender: Tender, teamId: string, failureFeedback: any[] = []): Promise<boolean> {
    var writePermissions = this.permissionsService.getWritePermissions();
    if (tender.guestValues) {
      await this.createGuestValues(tender.guestValues, teamId, writePermissions).then(async createdGuestValues => {
        // Create relation row for Tender and GuestValues
        await this.createTenderGuestValuesRelation(createdGuestValues.$id, tender.$id, writePermissions, teamId);

      }).catch(() => {
        failureFeedback?.push({
          tenderId: tender.$id,
          reason: `Unable to create guestvalues`,
          tenderName: tender.tenderName
        });
      });
    }

    if (tender.arrangements) {
      await Promise.all(tender.arrangements.map(async (arrangement) => {
        // Create arrangement
        arrangement.idInTender = tender.$id;
        await this.createArrangement(arrangement, teamId, writePermissions).catch(() => {
          failureFeedback?.push({
            tenderId: tender.$id,
            reason: `Unable to create arrangement: ${arrangement.name}`,
            tenderName: tender.tenderName
          })
        });
      }));
    }

    if (tender.timeLine?.timeLineItems) {
      await Promise.all(tender.timeLine.timeLineItems.map(async (timeLineItem) => {
        timeLineItem.tenderId = tender.$id;
        return await this.timeLineService.createTimeLine(timeLineItem, teamId, writePermissions).catch(() => {
          failureFeedback?.push({
            tenderId: tender.$id,
            reason: `Unable to create timeline: ${timeLineItem.description}`,
            tenderName: tender.tenderName
          })
        });
      }));
    }

    if (tender.partyValues?.checklists) {
      await Promise.all(tender.partyValues.checklists.map(checklist => {
        checklist.tenderId = tender.$id;

        return this.checklistService.addTenderChecklist(checklist, teamId, writePermissions).then(async createdChecklist => {
          if (createdChecklist && checklist.checklistItems) {
            await Promise.all(checklist.checklistItems.map(checklistItem => {
              checklistItem.checklistId = createdChecklist.$id;
              return this.checklistService.addTenderChecklistItem(checklistItem, teamId, writePermissions).catch(() => {
                failureFeedback?.push({
                  tenderId: tender.$id,
                  reason: `Unable to add CheckListItem: ${checklistItem.$id} - ${checklistItem.name}`,
                  tenderName: tender.tenderName
                })
              });
            }));
          }
        })
      }));
    }
    return true;
  }

  /**
   * Update an existing arrangement
   * @param arrangement 
   */
  updateArrangement(arrangement: Arrangement) {
    return Api.database.updateDocument(environment.database, "TenderArrangements", arrangement.$id, this.createPartialArrangement(arrangement));
  }

  /**
   * Remove an existing arrangement
   * @param arrangement 
   */
  deleteArrangement(arrangement: Arrangement) {
    return Api.database.deleteDocument(environment.database, "TenderArrangements", arrangement.$id);
  }

  /**
   * Update guestvalues
   * @param guestValues 
   * @param teamId 
   * @returns 
   */
  updateGuestValues(guestValues: GuestValues) {
    // Save guest values
    return Api.database.updateDocument(environment.database, "GuestValues", guestValues.$id, this.createPartialGuestValues(guestValues));
  }

  /**
   * This method will delete the given tender and the belonging arrangements
   * @param tender 
   * @returns 
   */
  async deleteTender(tender: Tender) {
    // Remove Tender
    return Api.database.deleteDocument(environment.database, "Tenders", tender.$id);
  }

  /**
   * This method will return a list with all guest values
   * @returns 
   */
  private async getAllGuestValues() {
    var resultCount = 50;
    var resultList: GuestValues[] = [];
    var lastId: string | undefined = undefined;
    var retrievedObjects: Models.DocumentList<GuestValues>;

    while (resultCount == 50) {
      if(lastId){
        retrievedObjects = await Api.database.listDocuments<GuestValues>(
          environment.database,
          "GuestValues",
          [Query.limit(50), Query.cursorAfter(lastId)]
        );
      } else {
        retrievedObjects = await Api.database.listDocuments<GuestValues>(
          environment.database,
          "GuestValues",
          [Query.limit(50)]
        );
      }

      lastId = retrievedObjects?.documents[retrievedObjects.documents.length - 1]?.$id;
      resultList.push(...retrievedObjects.documents);
      resultCount = retrievedObjects.documents.length;
    }

    return resultList;
  }

  /**
   * Clear all invalid refs
   * @param establishmentId 
   */
  async purgeRefs(establishmentId: string) {
    var establishmentTenders = await this.getTendersByEstablishmentId(establishmentId, false);

    // GuestValues
    this.getAllGuestValues().then(guestValues => {
      if (guestValues) {
        guestValues.map(async values => {
          if (establishmentTenders.filter(t => t.$id == values.tenderId).length == 0) {
            await this.deleteGuestValues(values.$id);
          }
        });
      }
    });

    // Checklists
    this.checklistService.getAllChecklists().then(checklists => {
      checklists?.map(async checklist => {
        if (establishmentTenders.filter(t => t.$id == checklist.tenderId).length == 0) {
          await this.checklistService.deleteTenderChecklist(checklist);

          checklist.checklistItems?.map(async checklistItem => {
            await this.checklistService.deleteTenderChecklistItem(checklistItem);
          })
        }
      })
    })

    // Arrangements
    this.arrangementService.getTenderArrangements().then(arrangements => {
      arrangements.map(async arrangement => {
        if (establishmentTenders.filter(t => t.$id == arrangement.idInTender).length == 0) {
          await this.deleteArrangement(arrangement);
        }
      });
    })

    // Tasks
    this.taskService.getAllTasksForEstablishment(establishmentId).then(tasks => {
      tasks.map(async task => {
        if (establishmentTenders.filter(t => t.$id == task.tenderId).length == 0) {
          await this.taskService.deleteTask(task.$id);
        }
      });
    })

    // TimelineItems
    this.timeLineService.getAllTimeLineItems().then(timelineItems => {
      timelineItems.map(async timelineItem => {
        if (establishmentTenders.filter(t => t.$id == timelineItem.tenderId).length == 0) {
          await this.timeLineService.deleteTimeLine(timelineItem);
        }
      });
    });
  }

  /**
   * This method will get the guest values of a tender if existing
   * @param tenderId 
   */
  getGuestValues(tenderId: string) {
    return Api.database.listDocuments<TenderGuestValuesRelation>(environment.database, "TenderGuestValues", [Query.equal('tenderId', tenderId)]).then(relations => {
      if(relations?.total > 0){
        return Api.database.getDocument<GuestValues>(environment.database, "GuestValues", relations.documents[0].guestValuesId);
      }
      return undefined;
    });
  }

  /**
   * This TEMP method will check if the releation exists
   * @param tenderId 
   * @returns 
   */
  checkIfGuestValuesRelationExists(tenderId: string){
    return Api.database.listDocuments<TenderGuestValuesRelation>(environment.database, "TenderGuestValues", [Query.equal('tenderId', tenderId)]).then(relations => {
      return relations.total > 0;
    });
  }

  /**
   * This method will get an arrangement by id
   * @param arrangementId 
   */
  getArrangement(arrangementId: string) {
    return Api.database.getDocument<Arrangement>(environment.database, "TenderArrangements", arrangementId);
  }

  /**
   * This method will get a tender by the given id and format it
   * @param tenderId 
   */
  getTenderById(tenderId: string): Promise<Tender | undefined> {
    return Api.database.getDocument<BTender>(environment.database, "Tenders", tenderId).then(async tender => {
      if (!tender) {
        return undefined;
      }

      // Try to get guest values
      return this.getGuestValues(tender.$id).then(async guestValues => {
        if(!guestValues){
          return;
        }

        // Convert and return;
        var mappedTender = this.bTenderToTender(tender, guestValues, [], [], []);

        await this.getArrangementsOfTender(tenderId).then(arrangements => {
          if (arrangements) {
            arrangements.forEach(arrangement => {
                if(arrangement.$id){
                  arrangement.uniqueIdentifier = arrangement.$id;
                }
            });

            mappedTender.arrangements = arrangements.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));
          }
        });

        // Get InvoiceValues if present
        await this.invoiceValuesService.geInvoiceValuesOfTender(tender.$id).then(invoiceValues => {
          if(invoiceValues.documents.length > 0){
            mappedTender.invoiceValues = invoiceValues.documents[0];

            // Set Ascription same to GuestValues if requested
            if(mappedTender.invoiceValues.ascriptionFromTender){
              // Use Company name if present, else use the first- and lastname
              if(guestValues.companyName){
                mappedTender.invoiceValues.ascription = guestValues.companyName;
              } else if(guestValues.insertion) {
                mappedTender.invoiceValues.ascription = `${guestValues.firstname} ${guestValues.insertion} ${guestValues.lastname}`;
              } else {
                mappedTender.invoiceValues.ascription = `${guestValues.firstname} ${guestValues.lastname}`;
              }
            }

            // Set EmailAdres same to GuestValues if requested
            if(mappedTender.invoiceValues.emailAdressFromTender){
              mappedTender.invoiceValues.emailAdress = guestValues.emailAdress;
            }
          } else {
            mappedTender.invoiceValues = {
              $id: "",
              tenderId: "",
              $collectionId: "",
              $createdAt: "",
              $databaseId: "",
              $permissions: [],
              $updatedAt: "",
              ascriptionFromTender: false,
              emailAdressFromTender: false
            }
          }
        });

        await this.timeLineService.getTimeLineOfTender(tenderId).then(timeLineItems => {
          if (timeLineItems) {
            if (!mappedTender.timeLine) {
              mappedTender.timeLine = { showInTender: tender.showTimeLineInTender, timeLineItems: [] }
            }

            mappedTender.timeLine.timeLineItems = [];
            timeLineItems.documents.forEach(timeLineItem => {
              mappedTender.timeLine!.timeLineItems.push({
                tenderId: timeLineItem.tenderId,
                $id: timeLineItem.$id,
                $collectionId: timeLineItem.$collectionId,
                $createdAt: timeLineItem.$createdAt,
                $databaseId: timeLineItem.$databaseId,
                $permissions: timeLineItem.$permissions,
                $updatedAt: timeLineItem.$updatedAt,
                description: timeLineItem.description,
                location: timeLineItem.location,
                startTimeLine: timeLineItem.startTimeLine,
                endTimeLine: timeLineItem.endTimeLine,
              });
            });

            mappedTender.timeLine.timeLineItems.sort((a, b) => (a.startTimeLine ?? "").localeCompare(b.startTimeLine ?? ""));
          }
        });

        await this.checklistService.getTenderChecklists(tender.$id).then(checklists => {
          if (mappedTender.partyValues && checklists) {
            mappedTender.partyValues.checklists = checklists;
          }
        });

        return mappedTender;
      })

    }).catch((appwriteException: AppwriteException) => {
      // Not found
      if(appwriteException.code == 404){
        return undefined;
      }

      return undefined;
    });
  }

  /**
   * This method will return a list of arrangements for the given tender id
   * @param tenderId 
   * @returns 
   */
  private async getArrangementsOfTender(tenderId: string): Promise<Arrangement[]> {
    var resultCount = 50;
    var resultList: Arrangement[] = [];
    var lastId: string | undefined = undefined;
    var retrievedObjects: Models.DocumentList<Arrangement>;

    while (resultCount == 50) {
      if(lastId){
        retrievedObjects = await Api.database.listDocuments<Arrangement>(
          environment.database,
          "TenderArrangements",
          [Query.limit(50), Query.equal('idInTender', tenderId), Query.cursorAfter(lastId)]
        );
      } else {
        retrievedObjects = await Api.database.listDocuments<Arrangement>(
          environment.database,
          "TenderArrangements",
          [Query.limit(50), Query.equal('idInTender', tenderId)]
        );
      }

      lastId = retrievedObjects?.documents[retrievedObjects.documents.length - 1]?.$id;
      resultList.push(...retrievedObjects.documents);
      resultCount = retrievedObjects.documents.length;
    }

    return resultList;
  }

  private async getTendersAfterCursor(establishmentId: string, tenders: Tender[], lastId: string, limit: number, includeRelations = true, tendersLazyLoading: BehaviorSubject<Tender[]> | undefined = undefined, lazyLoading: BehaviorSubject<boolean> | undefined = undefined, noDate: boolean = false){

    // Retrieve Items from LastId position
    var pageResults: Models.DocumentList<BTender>;
    if(noDate){
      pageResults = await Api.database.listDocuments<BTender>(environment.database, "Tenders", [Query.equal('establishmentId', establishmentId), Query.isNull('dateOfEvent'), Query.limit(limit), Query.cursorAfter(lastId)]);
    } else{
      pageResults = await Api.database.listDocuments<BTender>(environment.database, "Tenders", [Query.equal('establishmentId', establishmentId), Query.isNotNull('dateOfEvent'), Query.orderDesc('dateOfEvent'), Query.limit(limit), Query.cursorAfter(lastId)]);
    }
    
    if(pageResults && pageResults?.documents?.length > 0){
      
      // Set LastId
      lastId = pageResults.documents[pageResults.documents.length - 1].$id;

      // Recursive call
      this.getTendersAfterCursor(establishmentId, tenders, lastId, limit, includeRelations, tendersLazyLoading, lazyLoading, noDate);

      // Retrieve all related fields async
      await Promise.all(pageResults.documents.map(async t => {
        var guestValues = await this.getGuestValues(t.$id);
        // Lazy load releations --> No Await
        if (includeRelations && guestValues) {
          var mappedTender = this.bTenderToTender(t, guestValues, [], [], []);

          // Get arrangements
          this.getArrangementsOfTender(t.$id).then(arrangements => {
            arrangements?.forEach(arrangement => {
              if(arrangement.$id){
                arrangement.uniqueIdentifier = arrangement.$id;
              }
            });

            mappedTender.arrangements = arrangements;
          });

          tenders?.push(mappedTender);
        } else if (guestValues){
          tenders?.push(this.bTenderToTender(t, guestValues, [], [], []));
        }
      }));

      // Update TendersList
      if(tenders){
        tendersLazyLoading?.next(tenders);
      }
    } else if(!noDate) {
      // Disable LazyLoading status
      lazyLoading?.next(false);
    }
  }
  

  async getTendersByEstablishmentId(establishmentId: string, includeRelations = true, tendersLazyLoading: BehaviorSubject<Tender[]> | undefined = undefined, lazyLoading: BehaviorSubject<boolean> | undefined = undefined): Promise<Tender[]> {
    var limit = 25;
    var tenders: Tender[] = [];
    this.communicationService.expectedNumberOfTenders?.next(-1);

    // Initial Run so retrieve first the items without a Tender Date
    var pageResultNoDateTenders = await Api.database.listDocuments<BTender>(environment.database, "Tenders", [Query.equal('establishmentId', establishmentId), Query.isNull('dateOfEvent'), Query.limit(limit)]);

    if(pageResultNoDateTenders && pageResultNoDateTenders?.documents?.length > 0){
      // Set LastId
      var lastId = pageResultNoDateTenders.documents[pageResultNoDateTenders.documents.length - 1].$id;
  
      // Recursive call
      this.getTendersAfterCursor(establishmentId, tenders, lastId, limit, includeRelations, tendersLazyLoading, lazyLoading, true);

      // Retrieve all related fields async
      await Promise.all(pageResultNoDateTenders.documents.map(async t => {
        var guestValues = await this.getGuestValues(t.$id);
        // Lazy load releations --> No Await
        if (includeRelations && guestValues) {
          var mappedTender = this.bTenderToTender(t, guestValues, [], [], []);

          // Get arrangements
          this.getArrangementsOfTender(t.$id).then(arrangements => {
            arrangements?.forEach(arrangement => {
              if(arrangement.$id){
                arrangement.uniqueIdentifier = arrangement.$id;
              }
            });

            mappedTender.arrangements = arrangements;
          });

          tenders?.push(mappedTender);
        } else if (guestValues){
          tenders?.push(this.bTenderToTender(t, guestValues, [], [], []));
        }
      }));

      // Update TendersList
      if(tenders){
        tendersLazyLoading?.next(tenders);
      }
    }

    // Initial Run so retrieve first page // Query.orderAsc('dateOfEvent'),
    var pageResults = await Api.database.listDocuments<BTender>(environment.database, "Tenders", [Query.equal('establishmentId', establishmentId), Query.isNotNull('dateOfEvent'), Query.orderDesc('dateOfEvent'),  Query.limit(limit)]);
          
    // Set expected number of results if not set yet
    if(this.communicationService.expectedNumberOfTenders?.getValue() ?? 0 != pageResults?.total + pageResultNoDateTenders.total){
      this.communicationService.expectedNumberOfTenders?.next(pageResults.total + pageResultNoDateTenders.total);
    }

    if(pageResults && pageResults?.documents?.length > 0){
      // Set LastId
      var lastId = pageResults.documents[pageResults.documents.length - 1].$id;
      
      // Recursive call
      this.getTendersAfterCursor(establishmentId, tenders, lastId, limit, includeRelations, tendersLazyLoading, lazyLoading);

       // Retrieve all related fields async
       await Promise.all(pageResults.documents.map(async t => {
        var guestValues = await this.getGuestValues(t.$id);
        // Lazy load releations --> No Await
        if (includeRelations && guestValues) {
          var mappedTender = this.bTenderToTender(t, guestValues, [], [], []);

          // Get arrangements
          this.getArrangementsOfTender(t.$id).then(arrangements => {
            arrangements?.forEach(arrangement => {
              if(arrangement.$id){
                arrangement.uniqueIdentifier = arrangement.$id;
              }
            });

            mappedTender.arrangements = arrangements;
          });

          tenders?.push(mappedTender);
        } else if (guestValues){
          tenders?.push(this.bTenderToTender(t, guestValues, [], [], []));
        }
      }));

      // Update TendersList
      if(tenders){
        tendersLazyLoading?.next(tenders);
      }
    } else {
        // Disable LazyLoading status
        lazyLoading?.next(false);
    }

    return tenders;
  }

  /**
   * This method will convert a BackEnd Tender into a FrontEnd Tender
   * @param bTender 
   * @param guestValues 
   * @returns 
   */
  private bTenderToTender(bTender: BTender, guestValues: GuestValues, arrangements: Arrangement[], timelineItems: TimeLineItem[], checklists: Checklist[]): Tender {
    var mappedTimeLineItems: TimeLineItem[] = [];
    var mappedChecklists: Checklist[] = checklists;

    if (timelineItems) {
      timelineItems.forEach(timeLineItem => {
        mappedTimeLineItems.push({
          tenderId: timeLineItem.tenderId,
          $id: timeLineItem.$id,
          $collectionId: timeLineItem.$collectionId,
          $createdAt: timeLineItem.$createdAt,
          $databaseId: timeLineItem.$databaseId,
          $permissions: timeLineItem.$permissions,
          $updatedAt: timeLineItem.$updatedAt,
          description: timeLineItem.description,
          location: timeLineItem.location,
          startTimeLine: timeLineItem.startTimeLine,
          endTimeLine: timeLineItem.endTimeLine
        });
      });
    }

    return {
      $id: bTender.$id,
      $collectionId: bTender.$collectionId,
      $createdAt: bTender.$createdAt,
      $databaseId: bTender.$databaseId,
      $permissions: bTender.$permissions,
      $updatedAt: bTender.$updatedAt,
      tenderName: bTender.tenderName,
      uniqueTenderNumber: bTender.uniqueTenderNumber,
      tenderStatus: bTender.tenderStatus,
      jgtVersion: bTender.jgtVersion,
      displayname: bTender.displayname,
      particularities: bTender.particularities,
      kitchenParticularities: bTender.kitchenParticularities,
      guestValues: {
        $id: guestValues.$id,
        $collectionId: guestValues.$collectionId,
        $createdAt: guestValues.$createdAt,
        $databaseId: guestValues.$databaseId,
        $permissions: guestValues.$permissions,
        $updatedAt: guestValues.$updatedAt,
        adressedToMultiplePeople: guestValues.adressedToMultiplePeople,
        city: guestValues.city,
        companyName: guestValues.companyName,
        emailAdress: guestValues.emailAdress,
        firstname: guestValues.firstname,
        insertion: guestValues.insertion,
        lastname: guestValues.lastname,
        number: guestValues.number,
        phonenumber: guestValues.phonenumber,
        street: guestValues.street,
        tenderId: guestValues.tenderId,
        zipcode: guestValues.zipcode,
      },
      partyValues: {
        amountOfGuests: bTender.amountOfGuests,
        checklists: mappedChecklists,
        dateOfEvent: bTender.dateOfEvent,
        duration: bTender.duration,
        partyName: bTender.partyName,
        startTime: bTender.startTime,
        endTime: bTender.endTime,
        eventTypeReference: bTender.eventTypeReference,
        displayChecklistsInTender: bTender.displayChecklistsInTender,
      },
      arrangements: arrangements,
      establishmentId: bTender.establishmentId,
      expirationDate: bTender.expirationDate,
      initialDeposit: bTender.initialDeposit,

      timeLine: {
        showInTender: bTender.showTimeLineInTender,
        timeLineItems: mappedTimeLineItems,
      },

      /* Version History */
      createdBy: bTender.createdBy,
      createdDate: bTender.createdDate,
      lastModifiedBy: bTender.lastModifiedBy,
      lastModifiedDate: bTender.lastModifiedDate,
      statusChangedBy: bTender.statusChangedBy,
      statusChangedFromTo: bTender.statusChangedFromTo,
      statusChangedDate: bTender.statusChangedDate,

      /* Active state */
      archived: bTender.archived
    }
  }

  private createPartialArrangement(arrangement: Arrangement): Partial<Arrangement> {
    if (!arrangement) {
      return {};
    }

    return {
      idInTender: arrangement.idInTender,
      name: arrangement.name,
      description: arrangement.description,
      internalDescription: arrangement.internalDescription,
      notice: arrangement.notice,
      amountOfPax: arrangement.amountOfPax,
      amountOfHour: arrangement.amountOfHour,
      price: arrangement.price,
      priceType: arrangement.priceType,
      categoryId: arrangement.categoryId,
      isCustomArrangement: arrangement.isCustomArrangement,
      hideOnKitchenSheet: arrangement.hideOnKitchenSheet,
      index: arrangement.index,
      vatPercentage: arrangement.vatPercentage,
      vatSplitPercentage: arrangement.vatSplitPercentage,
      invoiceAmountOfHour: arrangement.invoiceAmountOfHour,
      invoiceAmountOfPax: arrangement.invoiceAmountOfPax,
      invoicePrice: arrangement.invoicePrice,
      visibility: arrangement.visibility
    };
  }

  private createPartialGuestValues(guestValues: GuestValues): Partial<GuestValues> {
    if (!guestValues) {
      return {};
    }

    return {
      firstname: guestValues.firstname,
      insertion: guestValues.insertion,
      lastname: guestValues.lastname,
      phonenumber: guestValues.phonenumber,
      emailAdress: guestValues.emailAdress,
      companyName: guestValues.companyName,
      city: guestValues.city,
      zipcode: guestValues.zipcode,
      number: guestValues.number,
      street: guestValues.street,
      adressedToMultiplePeople: guestValues.adressedToMultiplePeople,
      tenderId: guestValues.tenderId
    }
  }

  private createPartialTender(tender: Tender, overrideDate: Date | undefined = undefined, overrideMetaData: TenderMetaData | undefined = undefined): Partial<BTender> {
    if (!tender) {
      return {};
    }

    return {
      tenderName: tender.tenderName ?? `${tender.guestValues?.lastname} ${tender.guestValues?.insertion}, ${tender.guestValues?.firstname}`,
      tenderStatus: tender.tenderStatus,
      uniqueTenderNumber: tender.uniqueTenderNumber,
      jgtVersion: tender.jgtVersion,
      displayname: tender.displayname,
      particularities: tender.particularities,
      kitchenParticularities: tender.kitchenParticularities,
      establishmentId: tender.establishmentId,
      expirationDate: tender.expirationDate,
      initialDeposit: tender.initialDeposit,

      /* Version History */
      createdBy: overrideMetaData ? overrideMetaData.createdBy : tender.createdBy,
      createdDate: overrideMetaData ? overrideMetaData.createdDate : tender.createdDate,
      lastModifiedBy: overrideMetaData ? overrideMetaData.lastModifiedBy : tender.lastModifiedBy,
      lastModifiedDate: overrideMetaData ? overrideMetaData.lastModifiedDate : tender.lastModifiedDate,
      statusChangedBy: overrideMetaData ? overrideMetaData.statusChangedBy : tender.statusChangedBy,
      statusChangedFromTo: overrideMetaData ? overrideMetaData.statusChangedFromTo : tender.statusChangedFromTo,
      statusChangedDate: overrideMetaData ? overrideMetaData.statusChangedDate : tender.statusChangedDate,

      /* Active state */
      archived: overrideDate ? false : tender.archived,

      dateOfEvent: overrideDate ? overrideDate : tender.partyValues?.dateOfEvent,
      partyName: tender.partyValues?.partyName,
      duration: tender.partyValues?.duration,
      startTime: tender.partyValues?.startTime,
      endTime: tender.partyValues?.endTime,
      amountOfGuests: tender.partyValues?.amountOfGuests,
      eventTypeReference: tender.partyValues?.eventTypeReference,
      displayChecklistsInTender: tender.partyValues?.displayChecklistsInTender,
      showTimeLineInTender: tender.timeLine?.showInTender,
    }
  }

  /**
   * This method will compare two tenders for changes and will return true if there are changes
   * @param initialTender 
   * @param currentTender 
   */
  compareForChanges(initialTender: Tender, currentTender: Tender) {
    var changes = false;
    // Guest values
    if (initialTender.guestValues && currentTender.guestValues) {
      if (JSON.stringify(initialTender.guestValues) !== JSON.stringify(currentTender.guestValues)) {
        changes = true;
      }
    }

    return changes;
  }

  /**
   * This method will return the list of possible tender status items
   */
  getTenderStatusList() {
    return [
      {
        value: "Concept",
        description: "Concept",
        iconSource: "bi bi-pencil",
        color: "#289DD2",
        id: 1
      },
      {
        value: "Wachten op Akkoord",
        description: "Wachten op Akkoord",
        iconSource: "bi bi-hourglass-split",
        color: "#ff6600",
        id: 3
      },
      {
        value: "Wacht op Aanpassing",
        description: "Wacht op Aanpassing",
        iconSource: "bi bi-hourglass-split",
        color: "#e6b800",
        id: 5
      },
      {
        value: "Definitief",
        description: "Definitief",
        iconSource: "bi bi-check-circle",
        color: "#198754",
        id: 2
      },
      {
        value: "Verlopen",
        description: "Verlopen",
        iconSource: "bi bi-clock-history",
        color: "#ff3333",
        id: 4
      },
      {
        value: "Herinnering Verstuurd",
        description: "Herinnering Verstuurd",
        iconSource: "bi bi-envelope-check",
        color: "#4747d1",
        id: 6
      },
      {
        value: "Niet Akkoord",
        description: "Niet Akkoord",
        iconSource: "bi bi-x-circle",
        color: "#404040",
        id: 7
      },
      {
        value: "Gefactureerd",
        description: "Gefactureerd",
        iconSource: "bi bi-receipt",
        color: "#af47d1",
        id: 8
      },
    ];
  }

    /**
   * This method will return the color of a status
   * @param status 
   * @returns 
   */
    getStatusColor(status: string) {
      if (!status) {
        return;
      }
  
      var statusResource = this.tenderStatusItems.filter(s => s.value == status);
  
      if (!statusResource || statusResource?.length == 0) {
        return;
      }
  
      return statusResource[0].color;
    }
  
     /**
     * This method will return the icon resource based on the status
     */
     getStatusIcon(status: string) {
      if (!status) {
        return;
      }
  
      var statusResource = this.tenderStatusItems.filter(s => s.value == status);
  
      if (!statusResource || statusResource?.length == 0) {
        return;
      }
  
      return statusResource[0].iconSource;
    }

  /**
   * This method will check if the deadline is within the next X days
   * @param deadline 
   * @returns 
   */
  dateInRange(date: Date | undefined, dateWarning: number, exactOnDate: boolean = false): boolean {
    if (!date) {
      return false;
    }

    const msInDay = 24 * 60 * 60 * 1000;

    const dRange = new Date();
    const eventDate = new Date(date);

    dRange.setHours(0);
    eventDate.setHours(0);

    var diff = Math.round(
      Math.abs(dRange.getTime() - eventDate.getTime()) / msInDay
    );
  
    if(exactOnDate && diff == dateWarning){
      return true;
    } else if (!exactOnDate && diff <= dateWarning){
      return true;
    }

    return false;
  }

  createTenderObject(currentVersion: string, establishmentId: string, userName: string) : Tender {
    return {
      $id: "",
      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $permissions: [],
      $updatedAt: "",
      establishmentId: establishmentId,
      tenderStatus: "Concept",
      jgtVersion: currentVersion,
      createdBy: userName ?? "Unknown",
      createdDate: new Date(),
      guestValues:
      {
        adressedToMultiplePeople: false,
        $id: "",
        $collectionId: "",
        $createdAt: "",
        $databaseId: "",
        $permissions: [],
        $updatedAt: "",
      },
      partyValues: {

      },
      invoiceValues: {
        $id: "",
        tenderId: "",
        $collectionId: "",
        $createdAt: "",
        $databaseId: "",
        $permissions: [],
        $updatedAt: "",
        ascriptionFromTender: false,
        emailAdressFromTender: false
      },
      timeLine: {
        timeLineItems: [{
          $id: "",
          tenderId: "",
          $collectionId: "",
          $createdAt: "",
          $databaseId: "",
          $permissions: [],
          $updatedAt: "",
        }],
        showInTender: false
      },
      arrangements: []
    }
  }

  async GetTenderStatistics(establishmentId: string, startDate: Date | undefined, endDate: Date | undefined, tenders: Tender[] | undefined): Promise<TenderStatistics | undefined> {
    if (!establishmentId) {
      return undefined;
    }

    var tendersList = tenders;
    if (!tendersList) {
      tendersList = await this.getTendersByEstablishmentId(establishmentId);
    }

    if (!tendersList) {
      return undefined;
    }

    var concept = 0;
    var waitForChanges = 0;
    var expired = 0;
    var approved = 0;
    var waitForApproval = 0;
    var reminderSend = 0;
    var archived = 0;
    var disagreed = 0;
    var invoiced = 0;

    tendersList.forEach(tender => {
      var inBetween = false;
      if (startDate && endDate) {
        if (tender.partyValues?.dateOfEvent) {
          var convertedDate = new Date(tender.partyValues.dateOfEvent);
          if (convertedDate >= startDate && convertedDate <= endDate) {
            inBetween = true;
          }
        }
      }

      if (!inBetween && startDate && endDate) {
        // Do nothing
      } else {
        if (!tender.archived) {
          switch (tender.tenderStatus) {
            case this.tenderStatusItems.filter(s => s.id == 1)[0].value:
              concept++;
              break;

            case this.tenderStatusItems.filter(s => s.id == 2)[0].value:
              approved++;
              break;

            case this.tenderStatusItems.filter(s => s.id == 3)[0].value:
              waitForApproval++;
              break;

            case this.tenderStatusItems.filter(s => s.id == 4)[0].value:
              expired++;
              break;

            case this.tenderStatusItems.filter(s => s.id == 5)[0].value:
              waitForChanges++;
              break;

            case this.tenderStatusItems.filter(s => s.id == 6)[0].value:
              reminderSend++;
              break;

            case this.tenderStatusItems.filter(s => s.id == 7)[0].value:
              disagreed++;
              break;

            case this.tenderStatusItems.filter(s => s.id == 8)[0].value:
            invoiced++;
            break;
          }
        } else {
          archived++;
        }
      }
    });

    return {
      approved: approved,
      concept: concept,
      expired: expired,
      waitForApproval: waitForApproval,
      waitForChanges: waitForChanges,
      reminderSend: reminderSend,
      archived: archived,
      disagreed: disagreed,
      invoiced: invoiced
    }
  }
}
