import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Tender } from 'src/app/shared/objects/tender';

@Component({
  selector: 'app-internal-notice',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './internal-notice.component.html',
  styleUrl: './internal-notice.component.scss'
})
export class InternalNoticeComponent {

  @Input() tender?: Tender;
  @Input() isReadOnly?: boolean;
}
