@if(tender){
    <div class="row">
        <div class="form-group col">
            <label for="particularities">Bijzonderheden <span class="badge bg-warning text-dark">Zichtbaar voor gasten</span></label>
            <textarea class="form-control" [disabled]="isReadOnly ?? false" maxlength="4000" id="particularities" rows="3" [(ngModel)]="tender.particularities"></textarea>
            @if(!isReadOnly){
                <div class="char-counter">
                    @if(tender.particularities){
                        <div class="length-warning">
                            @if(tender.particularities.length >= 4000){
                                <i class="bi bi-exclamation-triangle-fill"></i>
                                <small>Het maximaal aantal tekens is 4000</small>
                            }
                        </div>
                        <small [ngClass]="tender.particularities.length >= 4000 ? 'exceeded' : 'counter'">{{tender.particularities.length}}/4000</small>
                    } @else {
                        <small>0/4000</small>
                    }
                </div>
            }
        </div>
    </div>
}