<div class="container-fluid hide-in-print" id="header" [style.background]="establishment?.establishmentBrandMainColor">
    <div class="brand-icon">
        <img src="{{logoReference}}" />
    </div>
    <div class="container">
        <div class="row justify-content-center header-section" id="header-content" [ngStyle]="{ 'color': textColor }">
            <div class="col header-title">
                @if(usedInEditMode){
                    <h1>Offerte bewerken</h1>
                } @else{
                    <h1>Offerte</h1>
                }
                <span>{{currentYear}} - Alle rechten voorbehouden</span>
            </div>
        </div>
    </div>
    <div class="header-actions">
        <button (click)="toggleShowContext()" [ngClass]="showContext ? 'btn btn-success active-button' : 'btn btn-secondary'" id="show-context"><i class="bi bi-info-circle"></i> Hulp</button>
        <button (click)="expandOrCollapseAll()" class="btn btn-secondary" id="expand-or-collpase-all"><i class="bi bi-chevron-bar-expand"></i>
            @if(expandAccordion){
                <span>Stappen uitklappen</span>
            } @else {
                <span>Stappen inklappen</span>
            }
        </button>
        <button (click)="pdfService.exportTender()" class="btn btn-secondary" id="generate"><i class="bi bi-file-earmark-text"></i> Exporteren</button>
        @if (!isReadOnly) {
            <button (click)="saveTender()" [disabled]="isReadOnly" type="button" class="btn btn-secondary" id="save-tender-values" [disabled]="isProcessingSavingTender">
                @if(isProcessingSavingTender){
                    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                }
                <i class="bi bi-save text-right"></i>Opslaan</button>
        }
    </div>
</div>