import { Component, Input, OnInit } from '@angular/core';
import { Tender } from 'src/app/shared/objects/tender';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InternalCommunicationService } from 'src/app/services/Communication/internal-communication.service';
import { CalculationService } from 'src/app/services/Calculation/calculation.service';
import { Arrangement } from 'src/app/shared/objects/components/arrangement';
import { VatSummaryComponent } from "../vat-summary/vat-summary.component";
import { EstablishmentSettings } from 'src/app/shared/objects/establishment-settings';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-invoice-summery',
  standalone: true,
  imports: [FormsModule, CommonModule, VatSummaryComponent],
  templateUrl: './invoice-summery.component.html',
  styleUrl: './invoice-summery.component.scss'
})
export class InvoiceSummeryComponent implements OnInit{

  @Input() tender?: Tender;
  @Input() usedInInvoice?: boolean;
  @Input() establishmentSettings?: EstablishmentSettings
  
  tenderTotal?: BehaviorSubject<number>;

  constructor(private calculationSerivce: CalculationService, private communicationService: InternalCommunicationService) {}

  ngOnInit(): void {
    let establishment = this.communicationService.activeEstablishment?.getValue();

    if(this.tender){
      this.tender.invoiceValues ??= {
         $id: "",
         $collectionId: "",
         $createdAt: "",
         $databaseId: "",
         $permissions: [],
         $updatedAt: "",
         ascriptionFromTender: establishment?.linkInvoiceAscriptionAndEmailAdress ?? false,
         emailAdressFromTender: establishment?.linkInvoiceAscriptionAndEmailAdress ?? false
      }
    }
  }

  /**
   * This method will calculate the total price of an arrangement
   * @param arrangement 
   * @returns 
   */
  getTotalPrice(arrangement: Arrangement){
    if(!this.tender || !arrangement){
      return "#";
    }

    return this.calculationSerivce.getTotalPriceForArrangement(arrangement, this.tender, this.usedInInvoice);
  }

  /**
   * This method will calculate the total price for the tender
   */
  getTotalTenderPrice(arrangements: Arrangement[] | undefined){
    if(!this.tender || !arrangements){
      return "#";
    }

    let totalPrice = 0;
    let invalidTotal = false;
    arrangements.forEach(arrangement => {
      let arrangementTotal = this.getTotalPrice(arrangement);

      // Return with Error
      if(arrangementTotal == "#"){
        invalidTotal = true;
        return; 
      }

      return totalPrice += Number(arrangementTotal);
    });

    if(invalidTotal){
      return "#";
    }

    this.tenderTotal ?? new BehaviorSubject<number>(0);
    this.tenderTotal?.next(totalPrice);

    return totalPrice.toFixed(2);
  }
}
