import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Models } from 'appwrite';
import { AuthService } from 'src/app/services/Auth/auth-service';
import { InternalCommunicationService } from 'src/app/services/Communication/internal-communication.service';
import { LogbookService } from 'src/app/services/Logbook/logbook.service';
import { PermissionService } from 'src/app/services/Permission/permission.service';
import { LogbookItem } from 'src/app/shared/objects/logbook-item';
import { Tender } from 'src/app/shared/objects/tender';
import { UserPreferences } from 'src/app/shared/objects/userPreferences';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    CommonModule
  ],
  selector: 'app-tender-logbook',
  templateUrl: './tender-logbook.component.html',
  styleUrls: ['./tender-logbook.component.scss']
})
export class TenderLogbookComponent implements OnInit, OnDestroy {

  @Input() tender?: Tender;
  @Input() isReadOnly?: boolean;

  newLogbookItem?: LogbookItem;
  user?: Partial<Models.User<UserPreferences>>;
  activeTeamId?: string;
  successFeedback?: string;
  subscribtions: (Subscription | undefined)[] = []; 

  constructor(private authService: AuthService, private logbookService: LogbookService, private permissionService: PermissionService, private communicationService: InternalCommunicationService) { 
    this.newLogbookItem = {
      $collectionId: "",
      $createdAt: "",
      $databaseId: "",
      $id: "",
      $permissions: [],
      $updatedAt: "",
    }
  }

  ngOnInit(): void {
    this.subscribtions.push(this.authService.user.subscribe(user => {
      if (!user) {
        return;
      }

      this.user = user;
    }));

    this.activeTeamId = this.communicationService.activeTeamId?.getValue();
  }

  ngOnDestroy(): void {
    this.subscribtions.forEach(s => s?.unsubscribe());
  }

  /**
   * Remove the given Logbook item
   * @param logbookItem 
   */
  removeLogbookItem(logbookItem: LogbookItem){
    if(!logbookItem){
      return;
    }

    this.logbookService.deleteLogbookItem(logbookItem).then(() => {
      if(this.tender?.logbookItems){
        this.tender.logbookItems = this.tender.logbookItems.filter(l => l != logbookItem);
        this.successFeedback = `Het logbook item is succesvol verwijderd`
        // Hide after 3 seconds
        setTimeout(() => { this.successFeedback = undefined }, 3000);
      }
    });
  }

  addLogbookItem(){
    if(!this.newLogbookItem){
      return;
    }

    this.newLogbookItem.createdBy = this.user?.name;
    this.newLogbookItem.createdDate = new Date();
    this.newLogbookItem.tenderId = this.tender?.$id,

    this.logbookService.createLogbookItem(this.newLogbookItem, this.activeTeamId!, this.permissionService.getWritePermissions()).then(result => {

      this.successFeedback = `Het logbook item is succesvol toegevoegd`
      // Hide after 3 seconds
      setTimeout(() => { this.successFeedback = undefined }, 3000);

      // Add to existing items
      this.tender?.logbookItems?.push(result);

      // Wipe current item
      this.newLogbookItem = {
        $collectionId: "",
        $createdAt: "",
        $databaseId: "",
        $id: "",
        $permissions: [],
        $updatedAt: ""
      }
    });
  }
}
