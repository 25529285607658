@if(tender){
    @if(usedInInvoice && tender.invoiceValues){
        <div class="row">
            <div class="col">
                <div class="d-flex flex-column">
                    @for(line of tender.invoiceValues.summary?.split('\n'); track $index){
                        @if(line){
                            <span>{{line}}</span>
                        } @else {
                            <span class="empty-line">-</span>
                        }
                    }
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <div class="totals d-flex justify-content-between">
                    <div class="totals-header">
                        Totaalprijs
                    </div>
                    <div class="summary-column center-column total-value">
                        <span>€ {{getTotalTenderPrice(tender.arrangements)}}</span>
                    </div>
                </div>
            </div>
        </div>
        <app-vat-summary [tender]="tender" [establishmentSettings]="establishmentSettings" [isExportComponent]="true" [tenderTotal]="tenderTotal" [arrangements]="tender.arrangements" [usedInInvoice]="true"></app-vat-summary>
    } @else if(!usedInInvoice) {
        <div class="row">
            <div class="col">
                <h4>Samenvatting</h4>
                <p>
                    Indien de gast enkel een samenvatting van hetgeen afgenomen wenst op de factuur, kan in onderstaand component een samenvatting worden opgegeven. Deze zal worden getoond op de factuur in plaats van de gekoppelde arrangementen. 
                    <br><strong>Let op:</strong> Zorg er wel voor dat de juiste arrangementen zijn gekoppeld met de juiste aantallen zodat het totaalbedrag klopt.
                </p>
            </div>
        </div>
        @if(tender.invoiceValues){
            <div class="row">
                <div class="form-group col">
                    <label for="invoiceSummaryInput">Factuuromschrijving</label>
                    <textarea class="form-control" maxlength="3000" id="invoiceSummaryInput" rows="3" [(ngModel)]="tender.invoiceValues.summary"></textarea>
                    <div class="char-counter">
                        @if(tender.invoiceValues.summary){
                            <div class="length-warning">
                                @if(tender.invoiceValues.summary.length > 3000){
                                    <i class="bi bi-exclamation-triangle-fill"></i>
                                    <small>Het maximaal aantal tekens is 3000</small>
                                }
                            </div>
                            <small [ngClass]="tender.invoiceValues.summary.length >= 3000 ? 'exceeded' : 'counter'">{{tender.invoiceValues.summary.length}}/3000</small>
                        } @else {
                            <small>0/3000</small>
                        }
                    </div>
                </div>
            </div>
        }
    }
}