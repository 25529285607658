import { Injectable } from '@angular/core';
import { ID, Permission, Query, Role } from 'appwrite';
import { Api } from 'src/app/helpers/api';
import { InvoiceValues } from 'src/app/shared/objects/components/invoice-values';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoiceValuesService {

  constructor() { }

  createInvoiceValues(invoiceValues: InvoiceValues, teamId: string, writePermissions: string[]) {
    return Api.database.createDocument(environment.database, "InvoiceValues", ID.unique(), this.createPartialInvoiceValues(invoiceValues), [Permission.read(Role.team(teamId)), ...writePermissions]);
  }

  geInvoiceValuesOfTender(tenderId: string){
    return Api.database.listDocuments<InvoiceValues>(environment.database, "InvoiceValues", [Query.equal('tenderId', tenderId)])
  }

  updateInvoiceValues(invoiceValues: InvoiceValues) {
    return Api.database.updateDocument(environment.database, "InvoiceValues", invoiceValues.$id, this.createPartialInvoiceValues(invoiceValues));
  }

  deleteInvoiceValues(invoiceValues: InvoiceValues) {
    return Api.database.deleteDocument(environment.database, "InvoiceValues", invoiceValues.$id);
  }

  isEmpty(invoiceValues: InvoiceValues) : boolean {
    var reducedInvoiceValues: Partial<InvoiceValues> = {
      ascription: invoiceValues.ascription,
      emailAdress: invoiceValues.emailAdress,
      vatId: invoiceValues.vatId,
      allocation: invoiceValues.allocation,
      KvkNumber: invoiceValues.KvkNumber,
      invoiceSummary: invoiceValues.invoiceSummary,
      $id: invoiceValues.$id,
      invoiceNumber: invoiceValues.invoiceNumber,
      summary: invoiceValues.summary
    }
    return Object.values(reducedInvoiceValues).every(x => x === undefined || x === null || x === '')
  }

  private createPartialInvoiceValues(invoiceValues: InvoiceValues) : Partial<InvoiceValues>{
    return {
      ascription: invoiceValues.ascription,
      ascriptionFromTender: invoiceValues.ascriptionFromTender,
      emailAdress: invoiceValues.emailAdress,
      emailAdressFromTender: invoiceValues.emailAdressFromTender,
      vatId: invoiceValues.vatId,
      allocation: invoiceValues.allocation,
      KvkNumber: invoiceValues.KvkNumber ,
      invoiceSummary: invoiceValues.invoiceSummary,
      tenderId: invoiceValues.tenderId,
      invoiceNumber: invoiceValues.invoiceNumber,
      summary: invoiceValues.summary
    };
  }
}
