import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/Auth/auth-service';
import { InternalCommunicationService } from 'src/app/services/Communication/internal-communication.service';
import { EstablishmentService } from 'src/app/services/Establishment/establishment.service';
import { HelperService } from 'src/app/services/Helper/helper.service';
import { PdfService } from 'src/app/services/PDF-service/pdf.service';
import { Establishment } from 'src/app/shared/objects/establishment';

@Component({
  selector: 'app-tender-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tender-header.component.html',
  styleUrl: './tender-header.component.scss'
})
export class TenderHeaderComponent implements OnInit, OnDestroy {

  @Input() showContext?: boolean;
  @Input() isReadOnly?: boolean;
  @Input() expandAccordion: boolean = false;
  @Input() isProcessingSavingTender?: boolean;
  @Input() usedInEditMode?: boolean;
  @Output() saveTenderEmitter = new EventEmitter();
  @Output() expandOrCollapseAllEmitter = new EventEmitter();
  @Output() showContextEmitter = new EventEmitter();
  
  textColor?: string;
  establishment?: Establishment;
  currentYear?: string;
  logoReference?: URL;
  subs: Subscription[] = [];

  constructor(public pdfService: PdfService, private establishmentService: EstablishmentService, private communicationService: InternalCommunicationService, private authService: AuthService, private helperService: HelperService){
    this.currentYear = new Date().getFullYear().toString();
  }

  async ngOnInit() {
    // Get user to edit
    var authUser = this.authService.user.getValue();
    if (authUser) {

      if (!authUser.prefs?.activeEstablishmentId) {
        return;
      }

      // Try get Establishment from CommunicationService --> Else load from database
      var loadedEstablishment = this.communicationService.activeEstablishment?.getValue();
            
      if(loadedEstablishment?.$id != authUser.prefs?.activeEstablishmentId) {
        loadedEstablishment = await this.establishmentService.getEstablishment(authUser.prefs?.activeEstablishmentId);
      }

      this.establishment = loadedEstablishment;
      this.subs.push(this.communicationService.activeEstablishmentLogoReference.subscribe(logoReference => {
        this.logoReference = logoReference;
      }));

      // Set TextColor of Header
      this.textColor = this.helperService.getTextColor(this.establishment?.establishmentBrandMainColor);
    }
  }

  ngOnDestroy(): void {
    this.subs.map(s => s?.unsubscribe());
  }

  /**
   * Expand or collapse all Accordion items
   */
  expandOrCollapseAll(){
    this.expandOrCollapseAllEmitter?.emit();
  }

  /**
   * Toggle the context visible state
   */
  toggleShowContext(){
    this.showContextEmitter?.emit();
  }

  /**
   * This method will emit the Save action given as input for this Component
   */
  saveTender(){
    this.saveTenderEmitter?.emit();
  }
}
