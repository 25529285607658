<div class="card details-card">
    <div class="card-header">
        <span class="float-end clickable" (click)="closePopUp(false)"><i class="bi bi-x-lg"></i></span>
        <h5><i class="bi bi-copy"></i> Succesvol gekopie&euml;rd</h5>
    </div>
    <div class="card-body">
        <div class="mt-2">
            <span>De offerte is succesvol gekopie&euml;rd naar <strong>{{copyToDate | date: "dd-MM-yyyy"}}</strong>. Eventueel aanwezige taken zijn niet mee gekopie&euml;rd.</span>
            <br>
            <span>Wilt u verder werken in deze offerte of in de gekopie&euml;rde offerte?</span>
            <div class="pop-up-actions">
                <button class="btn btn-secondary" (click)="closePopUp(false)">Deze offerte bewerken</button>
                <button class="btn btn-secondary" (click)="closePopUp(true)">Gekopie&euml;rde offerte bewerken</button>
            </div>
        </div>
    </div>
</div>