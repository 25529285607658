<div class="container logbook-items">
    <div class="row">
        @if(!tender?.logbookItems || tender?.logbookItems?.length == 0){
            <div class="col">
                <div class="alert alert-warning" role="alert">
                    <strong>Whoops!</strong> Er zijn nog geen logboek-items bekend voor deze offerte.
                </div>
            </div>
        }
        @if(successFeedback){
            <div class="col">
                <div class="alert alert-success" role="alert">
                    <strong><i class="bi bi-check-circle"></i></strong> {{successFeedback}}.
                </div>
            </div>
        }
    </div>
    <div class="row">
        @if((tender?.logbookItems?.length ?? 0) > 0){
            <div class="col">
                @for(logbookItem of tender!.logbookItems; track logbookItem.$id){
                    <div class="logbook-item">
                        <span class="message">Bericht:</span>
                        <p>{{logbookItem.message}}</p>
                        <div class="logbook-item-meta-data">
                            <div class="logbook-actions">{{logbookItem.createdDate | date: 'dd-mm-yyyy HH:mm'}} - <strong class="created-by">{{logbookItem.createdBy}}</strong>
                                <button class="btn btn-danger remove-button" [disabled]="isReadOnly" (click)="removeLogbookItem(logbookItem)">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
    </div>
    @if(newLogbookItem && !isReadOnly){
        <div class="row new-logbook-item">
            <div class="col">
                <div class="form-group col">
                    <label for="logbookItems">Bericht</label>
                    <textarea class="form-control" maxlength="1000" id="logbookItems" rows="3" [(ngModel)]="newLogbookItem.message"></textarea>
                    <div class="char-counter">
                        @if(newLogbookItem.message){
                            <div class="length-warning">
                                @if(newLogbookItem.message!.length >= 1000){
                                    <i class="bi bi-exclamation-triangle-fill"></i>
                                    <small>Het maximaal aantal tekens is 1000</small>
                                }
                            </div>
                            <small [ngClass]="newLogbookItem.message.length >= 1000 ? 'exceeded' : 'counter'">{{newLogbookItem.message.length}}/1000</small>
                        } @else {
                            <small>0/1000</small>
                        }
                    </div>
                </div>
                <div class="logbook-item-actions">
                    <button type="button" class="btn btn-success" id="add-custom-arrangement" data-bs-toggle="tooltip" data-bs-placement="top" title="Nieuw item toevoegen" (click)="addLogbookItem()"> + </button>
                </div>
            </div>
        </div>
    }
</div>