import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from 'ngx-modal-ease';

@Component({
  selector: 'app-clone-tender',
  standalone: true,
  imports: [DatePipe],
  templateUrl: './clone-tender.component.html',
  styleUrl: './clone-tender.component.scss'
})
export class CloneTenderComponent {

  // Injected by ModalService
  copyToDate?: Date;
  idOfDuplicatedTender?: string;

  constructor(private modalService: ModalService, private router: Router){

  }

  /**
   * This method will close the pop-up and open the copied tender if needed
   * @param openCopiedTender 
   */
  closePopUp(openCopiedTender: boolean){
    if(openCopiedTender && this.idOfDuplicatedTender){
      var route = '/edit-tender/' + this.idOfDuplicatedTender;
      this.router.navigate([route]).then(() => {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl]);
        });
      })
    }

    this.modalService.close();
  }
}
