import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Tender } from 'src/app/shared/objects/tender';

@Component({
  selector: 'app-tender-particularities',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './tender-particularities.component.html',
  styleUrl: './tender-particularities.component.scss'
})
export class TenderParticularitiesComponent {

  @Input() tender?: Tender;
  @Input() isReadOnly?: boolean;
}
